import { OrderStatus } from '@dep/common/shop-api/enums/order.enum';

import { ModelAbstract } from '../abstracts/model.abstract';

import { IModelUser } from '@dep/frontend/shop/tmp-utilities/core-api/interfaces/user.interface';
import {
  IModelOrder,
  IOrderHistory, IOrderEvent,
  IOrderBillingData, IOrderShippingData, IOrderExtraData, IAPIRecordOrder, IOrderProduct,
} from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/order.interface';

export class OrderModel extends ModelAbstract implements IModelOrder {
  public path: string;
  public status: OrderStatus;
  public locale: string;
  public locked: boolean;
  public created: Date;
  public updated: Date;
  public statusHistory: IOrderHistory[];
  public events: IOrderEvent[];
  public requestId: string;
  public userId: number;
  public billingData: IOrderBillingData;
  public shippingData: IOrderShippingData;
  public extraData: IOrderExtraData;
  public user?: IModelUser;
  public products?: IOrderProduct[];
  public productAbstractTitle?: { [key: string]: string };

  /**
   * Constructor.
   *
   * @param data - Data object to hydrate the model with.
   */
  constructor(data: IAPIRecordOrder) {
    super(data.id, data.created ? new Date(data.created) : new Date(), data.updated ? new Date(data.updated) : new Date());
    this.path = data.path;
    this.status = data.status;
    this.locale = data.locale;
    this.locked = data.locked;
    this.created = new Date(data.created);
    this.updated = new Date(data.updated);
    this.statusHistory = data.statusHistory;
    this.events = data.events;
    this.requestId = data.requestId;
    this.userId = data.userId;
    this.billingData = data.billingData;
    this.shippingData = data.shippingData;
    this.extraData = data.extraData;
    this.user = data.user;
    this.products = data.products;
    if (this.products && this.products.length > 0 && this.products[0].shopProduct) {
      this.productAbstractTitle = this.products[0].shopProduct.abstract.title;
    }
  }
}
